/* .about_container{
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 2%;
    margin-left: 8%;
    margin-right: 8%;
}

.heading_breaker{
    margin-left: 40%;
    margin-right: 40%;
}
.about_container_caption{
   
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 200px;
    
}

.about_container_caption_heading{
    color: #000;
    opacity: 0.6;
}

 */

 .company-profile {
    background: linear-gradient(to right, #d9f2ff, #add8e6);
    padding: 50px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.company-profile::before,
.company-profile::after,
.company-profile .shape1,
.company-profile .shape2,
.company-profile .shape3,
.company-profile .shape4 {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    z-index: 0;
}

/* .company-profile::before {
    top: 0;
    left: 0;
    border-top: 150px solid #add8e6;
    border-bottom: 800px solid #add8e6;
    border-right: 100px solid transparent;
} */
.company-profile::before {
    top: 0;
    left: 0;
    border-top: 150px solid #add8e6;
    border-bottom: 800px solid #add8e6;
    border-right: 100px solid transparent;
}


/* .company-profile::after {
    bottom: 0;
    right: 0;
    border-top: 150px solid #add8e6;
    border-bottom: 150px solid #add8e6;
    border-left: 100px solid transparent;
}

.company-profile::before .shape1 {
    top: 0;
    right: 0;
    border-top: 200px solid rgba(173, 216, 230, 0.5);
    border-left: 200px solid transparent;
}

.company-profile .shape2 {
    bottom: 0;
    left: 0;
    border-bottom: 200px solid rgba(173, 216, 230, 0.5);
    border-right: 200px solid ;
}

.company-profile .shape3 {
    top: 20%;
    left: 10%;
    border-top: 100px solid rgba(173, 216, 230, 0.3);
    border-left: 100px solid ;
}

.company-profile .shape4 {
    bottom: 20%;
    right: 10%;
    border-bottom: 100px solid rgba(173, 216, 230, 0.3);
    border-right: 100px solid transparent;
}
 */
.profile-content {
    position: relative;
    z-index: 1;
    max-width: 800px;
    margin: auto;
}

.profile-content h2 {
    font-size: 2.5em;
    color: #333;
    margin: 0;
}

.profile-content hr {
    width: 50px;
    border: 2px solid #333;
    margin: 20px auto;
}

.profile-content h3 {
    font-size: 1.5em;
    color: #333;
    margin: 20px 0;
}

.profile-content p {
    font-size: 1.1em;
    color: #333;
    margin: 10px 0;
}

.cta-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #0056b3;
}

