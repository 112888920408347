.mechanical_cover{
    width: 100%;
    height: 300px;
}
.mechanical_section{
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 2%;
    margin-left: 8%;
    margin-right: 8%;
}

.mechanical_heading{
    background-color: rgb(17, 17, 65);
    color: white;
    text-transform: capitalize;
}