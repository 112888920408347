.contact-section {
    font-family: Arial, sans-serif;
    text-align: left;
    padding: 20px;
    color: white;
    background-color: #6D7B83;
    height: 400px;
    
}

.contact-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 4%;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 8%;
    margin-right: 8%;
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #1C9AD7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.icon img {
    width: 30px;
    height: 30px;
}

.info {
    font-size: 18px;
}

.info a {
    color: white;
    text-decoration: none;
}

.info a:hover {
    text-decoration: underline;
}
