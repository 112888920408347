.categories-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    gap: 20px;
}

.categories-item {
    width: 150px;
    height: 80px;
    object-fit: cover;
    border-radius: 15px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.categories-item:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 3px solid #007bff;
}


