@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap');

.footer_container{
    background-color: #003366;
    color: white;
    
    padding: 20px;
}

.company_name{
    color: #fff;
    font-family: 'Monomaniac One', sans-serif;
}

.footer_logo{
    margin-top: 2%;
    width: 10%;
    height: 10%;
    margin-left: 5%;
    margin-right: 5%;
}
.footer_breaker{
    margin-left: 5%;
    margin-right: 5%;
}
.footer_navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%;
}

.footer_navigation p {
    margin: 0;
}

.footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-links li {
    margin: 0 10px;
}

.footer-links a {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9em;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: white;
}

.social-media {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.social-media li {
    margin-left: 10px;
}

.social-media li a img {
    height: 30px;
    transition: transform 0.3s;
}

.social-media li a:hover img {
    transform: scale(1.1);
}
