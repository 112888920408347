.product_card{
    width: 450px;
    height: 120px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* border-radius: 15px; */
    margin-top: 25px;
    
}

/* .product_card:hover{
    border: 3px solid #007bff;
} */
.prodcut_caption{
    display: flex;
}

.product_image{
    width: 170px;
    height: 120px;
}
.product_description{
    margin-left: 10px;
}