.productShowcase_container {
    /* margin-bottom: 1.5rem; */
    text-align: center;
    margin-top: 5%;
    margin-left: 8%;
    margin-right: 8%;
}
.ShowcaseCard_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
}
