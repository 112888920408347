.header_image{
    height: 600px;
}

.header_caption{
    width: 50%;
    margin-bottom: 10%;
    text-align: left;
    text-transform:capitalize;

}