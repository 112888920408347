.partner_container{
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 5%;
    margin-left: 8%;
    margin-right: 8%;
}
.partner_slider{
    margin-top: 5%;
}
.heading_breaker{
    margin-left: 40%;
    margin-right: 40%;
}