/* .service_container{
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 5%;
    margin-left: 8%;
    margin-right: 8%;
  
}



.service_container_slider{
    margin-top: 5%;
}

.service_paragraph{
    padding-left: 10%;
    padding-right: 10%;
}


.slick-arrow{
    color: #000;

}

.service-img{
    display: block;
    margin: auto;
    width: 75px;
    height: 75px;
} */



.service-container {
    text-align: center;
    /* background-color: #003366; */
    background-image: url("../images/service-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    margin-top: 5%;
    color: #fff;

}
.service_container_heading{
    color: #fff;
}

.heading_breaker{
    margin-left: 8%;
    margin-right: 8%;
}

.main-service{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.service-content{
    width: 50%;
    text-align: center;
    margin-top: 50px;
}
  
  .title {
    font-size: 50px;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
    
  }
  
  
  .services {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .service {
    width: 110px;
    height: 120px;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
    border-radius: 15px;
    transition: transform 0.3s, box-shadow 0.3s;

  }
  
  .service img {
    width: 75px;
    height: 75px;
    display: block;
    margin: auto;
    
  }

  .service:hover{
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 3px solid #007bff;
  }
  
  .service-title {
    margin: 10px 0;
  }