.Card {
    position: relative;
    width: 400px;
    height: 300px;
    margin: 1rem auto;
    background-color: #fff;
    color:#fff;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .cardCaption {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: #009ee3;
    padding: 15px;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;
  }
  
  .card-title {
    margin-top: 0px;
  }
  
  .card-content {
    margin: 0;
  }
  
  .Caption-link {
    color: #fff;
    text-decoration: underline;
    opacity: .8;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
            transition-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  }
  .Caption-link:hover {
    opacity: 0.65;
  }
  
  .card-Slide-up:hover .cardCaption {
    background-color: #000;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: opacity 0.1s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.1s;
    transition: transform 0.4s, opacity 0.1s, -webkit-transform 0.4s;
  }
  