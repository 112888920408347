.latest_news_container{
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: 5%;
    margin-left: 8%;
    margin-right: 8%;
}

.news_card{
    display: flex;
    text-align: left;
    justify-content: space-between;
    margin-top: 3%;
}